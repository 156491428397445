import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import axios from 'axios'

function AcceptenceChart() {
    const [post, setPosts] = useState(() => { })

    useEffect(() => {
        axios.get('https://run.mocky.io/v3/22ab0009-0210-4f4d-b2db-5a9a0b70d0ec')
            .then(res => {
                // console.log("data", res)
                setPosts(res.data)
            })
            .catch(err => {
                alert(err)
            })
    })
    const series = [{
        name: post?.data?.[0].name,
        data: post?.data?.[0].data,
    }, {
        name: post?.data?.[1].name,
        data: post?.data?.[1].data,
    },];
    const options = {
        chart: {
            type: 'bar',
            height: 350
        },
        title: {
            text: 'Acceptance Booking',
            align: 'left'
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: post?.subject,
        },
        yaxis: {

        },
        fill: {
            opacity: 1,
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return "$ " + val + " thousands"
                }
            }
        }
    };
    return (
        <div style={{ marginRight: '10px', marginTop: '10px' }} >
            <ReactApexChart options={options} series={series} type="bar" height={250} width={700} align={'right'} />
        </div>

    )
}

export default AcceptenceChart
