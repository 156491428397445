import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import axios from 'axios'

function Users() {
    const [post, setPosts] = useState(() => { })

    useEffect(() => {
        axios.get('https://run.mocky.io/v3/50f73875-83b5-4faa-b509-33eccfb98802')
            .then(res => {
                console.log("data", res)
                setPosts(res.data)
            })
            .catch(err => {
                alert(err)
            })
    }, [])
    const options = {
        title: {
            text: 'User',
            align: 'left'
        },
        labels: ['Apple', 'Mango'],
        legend: {
            position: 'bottom'
        },
    };
    const series = post?.data;
    return (
        <div style={{ position: 'absolute', top: '340px', left: '10px' }}>
            <Chart options={options} series={series} type="donut" width="340" height={250} />
        </div>
    )
}

export default Users
