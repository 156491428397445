import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import axios from 'axios'

function TransactionChart() {

  const [post, setPosts] = useState(() => { })

  useEffect(() => {
    axios.get('https://run.mocky.io/v3/78e75efa-f7b5-4e07-80d6-6e62a274d08a')
      .then(res => {
        setPosts(res.data)
      })
      .catch(err => {
        alert(err)
      })
  })
  const series = [{
    name: "Desktops",
    data: post?.data
  }];
  const options = {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    title: {
      text: 'Transaction Collected',
      align: 'left'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    xaxis: {
      categories: post?.categories,
    }
  };
  return (
    <div style={{ marginRight: '10px', marginTop: '10px' }}>
      <ReactApexChart options={options} series={series} type="line" height={250} width={650} align={'right'} />
    </div>
  )
}

export default TransactionChart
